import axios, { AxiosRequestConfig } from 'axios'
import authModule from '@/store/auth'

const BASE_URL = process.env.VUE_APP_API_URL
const BASE_PROTOCOL = process.env.VUE_APP_API_PROTOCOL
const timeout = 50000
function selectDefaultTenant() {
  const getUrl = window.location
  const protocol = getUrl.protocol
  const url = getUrl.origin.replace(protocol + '//', '')
  const TENANT = url.split('.').length === 1 ? '' : url.split('.')[0] + '.'
  return `${BASE_PROTOCOL}://${BASE_URL}`
}

const http = axios.create({
  baseURL: selectDefaultTenant(),
  timeout: timeout,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
} as AxiosRequestConfig)

export const httpMulti = axios.create({
  baseURL: selectDefaultTenant(),
  timeout: timeout,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'multipart/form-data',
  },
})

export const httpFile = axios.create({
  baseURL: selectDefaultTenant(),
  timeout: timeout,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'multipart/form-data',
  },
  responseType: 'blob',
})

http.interceptors.request.use(
  (config) => {
    const token = authModule.AccessToken
    config.headers.Authorization = token ? `Bearer ${token}` : ''
    config.baseURL = selectDefaultTenant()
    //tokenInterceptor(config)
    return config
  },
  (error) => {
    Promise.reject(error)
  },
)

http.interceptors.response.use(
  (res) => {
    return res
  },
  async (error) => {
    return await errorInterceptor(error) // handle the request && error.message === 'Token has expired' && getToken()
  },
)

httpFile.interceptors.request.use(
  (config) => {
    const token = authModule.AccessToken
    config.headers.Authorization = token ? `Bearer ${token}` : ''
    //tokenInterceptor(config)
    config.baseURL = selectDefaultTenant()
    return config
  },
  (error) => {
    Promise.reject(error)
  },
)

httpMulti.interceptors.request.use(
  (config) => {
    const token = authModule.AccessToken
    config.headers.Authorization = token ? `Bearer ${token}` : ''
    //tokenInterceptor(config)
    config.baseURL = selectDefaultTenant()
    return config
  },
  (error) => {
    Promise.reject(error)
  },
)

httpMulti.interceptors.response.use(
  (res) => {
    return res
  },
  (error) => {
    return errorInterceptor(error) // handle the request && error.message === 'Token has expired' && getToken()
  },
)

httpFile.interceptors.response.use(
  (res) => {
    return res
  },
  (error) => {
    return errorInterceptor(error) // handle the request && error.message === 'Token has expired' && getToken()
  },
)
// const isCorrectRefreshError = (error: { response: { status: number, data: any } }) =>
//  error.response.status === 401

const errorInterceptor = async (error: any) => {
  const originalConfig = error.config

  if (error.response) {
    // Access Token was expired
    if (
      error.response.status === 401 &&
      !['api/account/token/refresh/', 'api/account/token_logout/'].includes(
        originalConfig.url,
      ) &&
      !originalConfig._retry
    ) {
      originalConfig._retry = true

      try {
        await authModule.refresh()
        const token = authModule.AccessToken
        http.defaults.headers.Authorization = token ? `Bearer ${token}` : ''
        return http(originalConfig)
      } catch (_error: any) {
        await authModule.logout()
        if (_error.response && _error.response.data) {
          return Promise.reject(_error.response.data)
        }
        return Promise.reject(_error)
      }
    }

    if (error.response.status === 403 && error.response.data) {
      return Promise.reject(error.response.data)
    }
  }

  return Promise.reject(error)
}

// const tokenInterceptor = async (config: AxiosRequestConfig) => {
//   const token = authModule.AccessToken
//   config.headers.Authorization = token ? `Bearer ${token}` : ''
//   if (
//     token !== null &&
//     config.url &&
//     !['account/token/refresh/', 'account/token_logout/'].includes(
//       config.url,
//     )
//   ) {
//     if (!authModule.isValidToken) {
//       await authModule.refresh()
//       config.headers.Authorization = token ? `Bearer ${token}` : ''
//     }
//   }
// }

export default http
